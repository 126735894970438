<template>
    <div>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">发布霸王餐</p>
        <van-cell-group>
            <van-field v-model="form.type_name" label="店铺类型" placeholder="" v-if="form.type_name" readonly/>
            <van-field v-model="form.remaining_times" label="剩余次数" placeholder="" v-if="form.remaining_times !== undefined" readonly/>
            <van-field v-model="form.shop_name" label="申请店铺" placeholder="请选择" is-link readonly
                       @click="shopSelect = true"/>
            <van-field v-model="form.goods_name" label="霸王餐活动" placeholder="请选择" is-link readonly
                       @click="goodsSelect = true"/>
        </van-cell-group>
        <p style="padding: 0 15px;font-size: 16px;">注意事项：</p>
        <p style="padding: 0 15px;font-size: 14px;margin: 5px 0;">1、请再次确认店铺<span style="color: red;font-weight: bold;">城市</span>和<span
                style="color: red;font-weight: bold;">地址</span>是否正确。霸王餐采用抽奖的形式发布，系统自动匹配同城的用户进行抽奖</p>
        <p style="padding: 0 15px;font-size: 14px;margin: 5px 0;">2、在“首页-我的店铺”可对店铺地址进行修改</p>
        <p style="padding: 0 15px;font-size: 14px;margin: 5px 0;">3、霸王餐每期抽奖活动持续时间为24小时(热门类为48小时)，如果参与人数达到1人(热门类为10人)将立即开奖</p>
        <p style="padding: 0 15px;font-size: 14px;margin: 5px 0;">4、中奖的达人将在领奖成功后给您致电沟通到店时间，并在领取日期起14天内光临您的店铺免费体验</p>
        <p style="padding: 0 15px;font-size: 14px;margin: 5px 0;">
            5、用户到店后，请查验用户昵称及兑奖码并在“首页-活动进度-霸王餐”对用户进行身份验证并点击“确认到店”按钮</p>
        <div style="width: 100%;padding:16px;box-sizing: border-box;">
            <van-button type="primary" block @click="beforeSubmit" :loading="submitting"
                        color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;">确定
            </van-button>
        </div>
        <van-popup v-model="shopSelect" position="bottom">
            <van-picker show-toolbar :columns="shops" value-key="shop_name" title="选择活动店铺" @cancel="shopSelect = false"
                        @confirm="shopConfirm"/>
        </van-popup>
        <van-popup v-model="goodsSelect" position="bottom">
            <van-picker show-toolbar :columns="goods" value-key="goods_name" :default-index="goodsIndex" title="选择霸王餐奖品"
                        @cancel="goodsSelect = false" @confirm="goodsConfirm"/>
        </van-popup>

        <van-dialog v-model="show" title="请确认您的选择" show-cancel-button confirm-button-text="确认发布" @confirm="submit">
            <div>
                <van-cell-group class="apply_group">
                    <van-cell title="店铺名">
                        <span style="color: red;">{{form.shop_name}}</span>
                    </van-cell>
                    <van-cell title="活动">
                        <span style="color: red;">{{form.goods_name}}</span>
                    </van-cell>
                    <van-cell title="店铺地址">
                        <span style="color: red;">{{form.short_name}}</span>
                    </van-cell>
                </van-cell-group>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";

    export default {
        name: "LotteryApply",
        data() {
            return {
                form: {
                    shop_id: '',
                    shop_name: '',
                    goods_id: '',
                    goods_name: '',
                    short_name: '',
                    type_name:'',
                },
                shopSelect: false,
                goodsSelect: false,
                shops: [],
                goods: [],
                goodsIndex: 0,
                remaining:[],
                submitting: false,
                show: false,
                shop_id: '',
            }
        },
        mounted() {
            let that = this;
            if(this.$route.query.shop_id !== undefined){
                this.shop_id = parseInt(this.$route.query.shop_id);
            }
            this.post('/api/client/activity/create/before').then(result => {
                if (result.code === 0) {

                    // 如果没有余额，返回个人中心
                    if(!result.data.remaining.length > 0) {
                        this.noRemaining();
                    }
                    // 检查是否还有余额
                    let remaining = result.data.remaining;
                    let count = false;
                    for(let i = 0; i<remaining.length;i++){
                        if(remaining[i].remaining_times > 0){
                            count = true;
                            break;
                        }
                    }
                    // 如果没有余额，返回个人中心
                    if(!count) {
                        this.noRemaining();
                    }
                    if (!result.data.shops.length) {
                        this.$dialog.alert({
                            title: '提示',
                            message: '您没有符合条件可发布霸王餐的店铺，请等待店铺审核通过并设置活动详情后再来发布',
                        }).then(() => {
                            this.$router.back();
                        });
                    }
                    this.shops = result.data.shops;
                    this.remaining = result.data.remaining;

                    if (this.shop_id !== '') {
                        let hit = false;
                        let shops = result.data.shops;
                        console.log(shops);
                        console.log(this.shop_id);
                        // 如果带参数进来的，需要遍历一遍查找对应的店铺直接选择
                        for (let i = 0; i < shops.length; i++) {
                            console.log(shops[i].shop_id);
                            if (shops[i].shop_id === that.shop_id) {
                                that.form.shop_id = shops[i].shop_id;
                                that.form.shop_name = shops[i].shop_name;
                                that.goods = shops[i].goods;
                                hit = true;
                                break;
                            }
                        }
                        if(!hit){ // 如果没有循环遍历到这个店铺，说明这个店铺状态刚刚被修改了导致没有审核通过
                            that.$dialog.alert({
                                title: '提示',
                                message: '您刚刚的店铺目前状态为未审核通过，请返回上一页面重试',
                            })
                        }
                    }
                } else {
                    Toast(result.msg);
                }
            })
        },
        methods: {
            noRemaining(){
                this.$dialog.alert({
                    title: '提示',
                    message: '您没有霸王餐余额，请先充值后再来发布霸王餐',
                }).then(() => {
                    this.$router.replace('/mine');
                });
            },
            shopConfirm(value) {
                let old_shop_id = this.form.shop_id;
                this.form.shop_id = value.shop_id;
                this.form.shop_name = value.shop_name;
                this.form.type_name = value.type_name;
                this.form.remaining_times = value.remaining_times;
                this.goods = value.goods;
                if (old_shop_id !== this.form.shop_id) {
                    this.form.goods_id = '';
                    this.form.goods_name = '';
                    this.goodsIndex = 0;
                }
                this.shopSelect = false;
            },
            goodsConfirm(value) {
                this.form.goods_id = value.goods_id;
                this.form.goods_name = value.goods_name;
                this.form.short_name = value.short_name;
                this.goodsSelect = false;
            },
            beforeSubmit() {
                if (!this.form.shop_id || !this.form.goods_id) {
                    this.$notify('请选择店铺及活动');
                    return;
                }
                this.show = true;
            },
            submit() {
                let that = this;
                this.submitting = true;
                this.post('/api/client/activity/create', this.form).then(result => {
                    if (result.code === 0) {
                        Dialog.alert({
                            title: '发布成功',
                            message: '霸王餐已发布成功，为保障您的服务权益，达人到店后请及时前往“首页-活动进度-霸王餐”进行核销。',
                        }).then(() => {
                            that.$router.back();
                        });
                    } else {
                        Dialog.alert({
                            title: '活动发起失败',
                            message: result.msg,
                        });
                    }
                    this.submitting = false;
                })
            },
        },
    }
</script>

<style scoped>
    .apply_group .van-cell__title {
        width: 70px;
        flex: 0 0 auto;
    }
</style>